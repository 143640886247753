<template>
<div>
    <v-dialog v-model="paymentDialog" fullscreen :scrollable="true" persistent>
    <v-card>
        <v-card-title class="text-xs-center">Available Payment Methods</v-card-title>
        <v-card-text>
            <v-progress-circular v-if="onlinePaymentLoading"  color="indigo" indeterminate></v-progress-circular>
            <div v-else>
                <v-alert :value="onlinePaymentData.length <= 0" type="info">No Payment data available</v-alert>
                <ul style="list-style: none">
                    <li v-for="(payment, pi) in onlinePaymentData" :key="pi" class="mt-1">
                        <template v-if="payment.type==='upi'">
                            <v-card>
                                <v-card-title><u>{{payment.type.toUpperCase()}}</u></v-card-title>
                                <v-card-text>
                                    <v-layout row wrap>
                                        <v-flex xs6>
                                            <h3 v-html="`UPI ID: <code>${payment.upi_id}</code>`"></h3>
                                            <p class="mt-2" v-html="'<b>Instruction:</b> ' + payment.instruction"></p>
                                        </v-flex>
                                        <v-flex xs6><v-img :src="payment.upi_qr_code" style="max-width: 340px;"/></v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                        </template>
                        <template v-if="payment.type==='sib' && feeItem">
                            <v-card>
                                <v-card-title><u>SOUTH INDIAN BANK ONLINE PAYMENT</u></v-card-title>
                                <v-card-text>
                                    <v-layout row wrap>
                                        <v-flex xs12>
                                            <p class="mt-2" v-html="'<b>Instruction:</b> ' + payment.instruction"></p>
                                        </v-flex>
                                        <v-flex xs12>
                                           <v-btn color="primary" @click="openPaymentWindow(feeItem._id)" :_href="`https://admin.purvinternationalschool.com/worldline/start?fee_id=${feeItem._id}`" target="_blank">PAY NOW</v-btn>
                                        </v-flex>
                                        <!-- <v-flex xs6><v-img :src="payment.upi_qr_code" style="max-width: 340px;"/></v-flex> -->
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                        </template>
                    </li>
                </ul>
            </div>
        </v-card-text>
        <v-card-actions justify-content-center><v-btn round @click="closeDialog">Close</v-btn></v-card-actions>
    </v-card>
    </v-dialog>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { instance } from '../../_axios'
export default{
    data(){
        return {
            onlinePaymentLoading: false,
            onlinePaymentData: [
                {type: 'UPI', QRCODE: '', address: 'abcd@hdfc'}
            ],
            endpoint: 'https://admin.purvinternationalschool.com'
        }
    },
    mounted(){

    },
    methods: {
        async showPayment(){
            this.onlinePaymentLoading = true
            try {
                let resp = await instance.get('/payment-methods')
                this.onlinePaymentLoading = false
                this.onlinePaymentData = resp.data.list
                this.endpoint = resp.data.endpoint
            } catch (error) {
                this.$store.commit('open_snackbar',{text: 'Failed fetching data!',color: 'red'})
            }
        },
        closeDialog() {
            this.$emit('close')
        },
        openPaymentWindow(pid){
            //let owindow = window.open(`https://admin.purvinternationalschool.com/fee-payment/process-sib?order_id=${pid}`)
            let owindow = window.open(`${this.endpoint}/worldline/start?fee_id=${pid}`)
        }
    },
    props: {
        paymentDialog: {
            type: Boolean,
            default: false
        },
        feeItem: {
            type: Object,
            default: () => { }
        },
       
        // onlinePaymentData: {
        //     type: Object,
        //     default: () => {
        //         return {
        //             UPI: null,
        //             RazorPay: false
        //         }
        //     }
        // }
    },
    watch: {
        'paymentDialog' (newval) {
            if(newval) {
                this.showPayment()
            }
        }
    },
    computed: {
        ...mapGetters(['user'])
    }
}
</script>