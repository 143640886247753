<template>
<div>
  <div style="height:auto; min-height: 64px">
    <router-link v-if="isB2B"  to="/" style="display: block;margin: 0 auto;text-align: center;">
      <img :src="logo" style="max-width:200px; max-height: 200px">
    </router-link>
    <router-link v-else  to="/">
      <img src="./../assets/logo.png" width="200px">
    </router-link>
    <p class="pl-2 mt-2 text-center">Session: {{ currentSession }}</p>
  </div>

  <v-divider></v-divider>

  <v-list class="pt-0">
    <v-list-tile
      to='/'
      v-ripple
      active-class="white--text success"
      >
      <v-list-tile-action>
        <v-icon>home</v-icon>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>Home</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile
      v-for="(item,pi) in items"
      @click.stop="activate(item,pi+1)"
      :key="item.title"
      :to='item.to'
      v-ripple
      active-class="white--text primary"
      class="home-menu-item"
      enter-active-class="animated tada"
      leave-active-class="animated bounceOutRight"
      >
      <v-list-tile-action>
        <v-icon v-if="!item.img">{{ item.icon }}</v-icon>
        <span v-else ><img width="24px" height="20px" :src="item.img"></span>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
      </v-list-tile-content>

      <v-list-tile-action v-if="item.right_icon">
        <v-icon>{{ item.right_icon }}</v-icon>
      </v-list-tile-action>
    </v-list-tile>

    <v-list-tile
      @click.stop="themeSelectorDialog = true"
      v-ripple
      active-class="white--text success"
      >
      <v-list-tile-action>
        <v-icon>invert_colors</v-icon>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>Theme</v-list-tile-title>
      </v-list-tile-content>

      <!--<v-list-tile-action >
        <v-icon>dark_mode</v-icon>
      </v-list-tile-action>-->
    </v-list-tile>
    <v-list-tile></v-list-tile>
  </v-list>
  <theme-selector :dialog="themeSelectorDialog" @close="themeSelectorDialog = false"></theme-selector>
</div>
    
</template>

<script>
import ThemeSelector from './theme-selector/ThemeSelector.vue'
import {mapGetters} from 'vuex'
import {getCurrentSession} from './../_axios.js'
import {filteredMenu} from './../data/sidebar-menu'

let logoData = require('../assets/logo-placeholder.png')
if(process.env.VUE_APP_LOGO) logoData = process.env.VUE_APP_LOGO
if(process.env.VUE_APP_HOST_LOGO) logoData = `https://s3.eu-central-1.wasabisys.com/subdomainlogo/${window.location.host}.png`


export default {
  data(){
      return {
        items: filteredMenu(this.user?this.user.config:null, 'There'),
        isB2B: process.env.VUE_APP_TYPE,
        logo: logoData,
        themeSelectorDialog: false,
        currentSession: getCurrentSession()
      }
  },
  methods: {
    activate(item, pi) {
      item.active = true;
    },
  },
  computed: {
    ...mapGetters(['user'])
  },
  mounted(){
    this.items = filteredMenu(this.user?this.user.config:null, 'Here')
  },
  watch: {
    user: {
      handler: function(val, oldVal) {
        if(val){
            this.items = filteredMenu(val.config)
        }
      },
      deep: false
    }
  },
  components:{
    'theme-selector': ThemeSelector
  }
}
</script>