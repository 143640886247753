<template>
  <div class="auth-form" >
    <!--<v-progress-circular  v-if="authenticating" :size="200" :width="7" color="blue" indeterminate>Authenticating user...</v-progress-circular>-->
    <v-form v-model="valid" ref="loginForm" @submit.prevent="submitAuth()">
        <v-flex xs12 >
            <v-select 
                placeholder="Select Session" 
                label="Session"
                v-model="user.session" :rules="[rules.required]" :items="['2024-2025', '2025-2026']" item-value="value" item-text="text">
              </v-select>
        </v-flex>
        <v-flex xs12 >
            <v-text-field
            :disabled="authenticating"
            v-model="user.phone"
            :rules="[rules.required]"
            :label="'Email or Reg. No.'"
            ></v-text-field>
        </v-flex>
        <v-text-field
            v-model="user.password"
            :rules="[rules.required, rules.passLength]"
            label="Password"
            :disabled="authenticating"
            :append-icon="showpass ? 'visibility' : 'visibility_off'"
            :type="showpass ? 'text' : 'password'"
            @click:append="showpass = !showpass"
        ></v-text-field>
        <v-btn type="submit" color="primary" dark round class="mb-4" :loading="authenticating">Login</v-btn>
        <div class="justify-center">
            <!-- <small>Don't remember the password? <router-link to="/password/reset">Reset here</router-link></small> -->
        </div>
    </v-form>
  </div>
</template>

<script>
import { redirect, logIn, register, registerPhone, verifyOTP } from './../../auth.js'
import { changeSession, getCurrentSession } from './../../_axios.js'

export default {
    data(){
        return {
            user: {
                name:  '',
                email: '',
                password: '',
                referral: this.$route.query.ref || '',
                OTP: null,
                class: null,
                city: null,
                session: getCurrentSession()
            },
            authenticating: false,
            valid: false,
            showpass: false,
            rules: {
                required:   value => !!value || 'Required.',
                passLength: value => value && value.length >= 6 || 'Min 6 characters',
                nameLength: value => value && value.length >= 3 || 'Min 3 characters',
                matches:    value => value=== this.user.password || 'Password doesn\'t match',
                email:      value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
                phone: (value) => {
                    var phoneno = /^\d{10}$/;
                    return (value && phoneno.test(value)) || 'Invalid phone number!'
                },
                phoneOrEmail: (value) => {
                    var phoneno = /^\d{10}$/;
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    
                    return (value && phoneno.test(value)) || (value && pattern.test(value)) || 'Please enter a valid email/phone number!'
                }
            },
        }
    },
    methods: {
        submitAuth(){
            this.$refs.loginForm.validate()
            this.$nextTick(() => {
                if(this.valid) logIn(this)
            })
        }
    },
    watch: {
        'user.session'(val){
            //console.log(val)
            changeSession(val)
        }
    }
}
</script>
