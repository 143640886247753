import store2  from './store.js'
const axios = require('axios')

const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 0,
  headers: { 
    "Access-Control-Allow-Origin": "*"
  } 
});

const setAccessToken = function(token) {
  instance.defaults.headers.common['x-access-token'] = token
  return instance
}

if(localStorage && localStorage.getItem('jwt_token')) {
	setAccessToken(localStorage.getItem('jwt_token'))
}

if(getCurrentSession()){
	changeSession(localStorage.getItem('tm_session'))
}

function getCurrentSession(){
  if(!localStorage) return ""
  return localStorage.getItem('tm_session')
}

function getADMINURL() {
	switch (getCurrentSession()){
		case "2024-2025": 
			return "https://admin.purvinternationschool.com"
		case "2025-2026":
			return "https://admin2025.purvinternationschool.com" //"https://quizglobal.thinkmerit.in"
		default: 
			return "http://localhost:3000"
	}
	return process.env.VUE_APP_ADMIN
}

function getBASEAPIURL(session) {
	switch (session){
		case "2024-2025": 
			return "https://quiz2api.thinkmerit.in/"
		case "2025-2026":
			return "https://quiz2api.thinkmerit.in/" //"https://quizglobal.thinkmerit.in"
		default: 
			return "http://localhost:3000"
	}
}

function changeSession(session) {
	instance.defaults.baseURL = getBASEAPIURL(session)
  if(session){
    console.log(session)
    instance.defaults.headers.common['session'] = session
  }
  localStorage.setItem('tm_session', session)
}


const deleteAccessToken = function(token) {
  delete instance.defaults.headers.common['x-access-token']
}

// const addInterceptor = function () {
//    instance.interceptors.request.use(function (config) {
//    	// if(!store.getters.auth){
//    	// 	console.log(config.url)
//    	// }
//     // Do something before request is sent
//     return config;
//   }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   });
// }
// addInterceptor()

const no_auth = ['/login','/third-party-auth','/register','/verifyOTP','/question/fetch','/course','/newUser','/user','/test/list','/password/resetMail','/password/forgotMail','/password/resetOTP','/password/forgotOTP','/merit-coins','/contact','/more-data','/desk','/video-lectures']

instance.interceptors.request.use( (config) => {
	// console.log(no_auth.filter(v => config.url.indexOf(v)>=0),config.url)
	if(no_auth.filter(v => config.url.indexOf(v)>=0).length) return config
	else {
		if(!store2.getters.auth) throw new axios.Cancel('Log in required!')
	}
		return config;
	}, (error) => {
    	return Promise.reject(error);
	});

instance.interceptors.response.use(function (response) {
	// Do something with response data
	// console.log(store2, store, store.default)
	return response;
}, function (error) {
	// console.log(error.response)
	// console.log(error.response.status)
	if(error.response && error.response.status==401){
		store2.dispatch('clearAuthenticated')
		store2.dispatch('clearToken')
	}

	// OTP verification required
	if(error.response && error.response.status==412){
		return Promise.reject(error);
	}

	// console.log(error.response)
	if (error.code === 'ECONNABORTED') return Promise.reject("Please check your internet connection!");
	if (error.response && error.response.data.message) return Promise.reject(error.response.data.message);
	if (error.message) return Promise.reject(error.message);
	// console.log(error)
	return Promise.reject(error);
});

export { instance, setAccessToken, deleteAccessToken, changeSession, getCurrentSession, getADMINURL }
// module.exports = {instance,setAccessToken,deleteAccessToken}